// LAYOUT
export const HEADER_HEIGHT = 64
export const FOOTER_HEIGHT = 32
export const MENU_ON_SESSION_HEIGHT = 48

// URL
export const LEGACY_PATH = '/'

// API
export const API_COMMON_CACHE_TIME = 1000 * 60 * 10 // 10분
export const API_URL = 'https://api-gw.oms.musinsalogistics.co.kr'
export const DEV_API_URL = {
  DEV: 'https://api-gw-dev.oms.musinsalogistics.co.kr',
  DEV2: 'https://api-gw-dev-2.oms.musinsalogistics.co.kr',
  DEV3: 'https://api-gw-dev-3.oms.musinsalogistics.co.kr',
  LOCAL: 'http://localhost:8080',
} as const

// REGEX
export const REGEX = {
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
  positiveNum: /^\d*[1-9]\d*$/,
  positiveNumWithZero: /^\d*[0-9]\d*$/,
  korean: /^[ㄱ-힣]+$/,
}

// DATE FORMAT
export const DATE_FORMAT = {
  date: 'YYYY-MM-DD',
  dateSimple: 'YYYYMMDD',
  dateNday: 'YYYY-MM-DD (ddd)',
  datetime: 'YYYY-MM-DD HH:mm:ss',
  datetimeNday: 'YYYY-MM-DD (ddd) HH:mm:ss',
}
