import * as React from 'react'
import { ReactElement, useMemo } from 'react'
import styled from 'styled-components'
import Button, { ButtonProps } from '@/components/content/Button'
import { HStack, VStack } from '@/components/content/Stack'
import { borderRadius, colorSystem } from '@/styles/theme'
import FieldList, { FieldListProps } from '@/components/content/FieldList'

interface Props extends FieldListProps {
  className?: string
  isLoading?: boolean
  buttons?: ButtonProps[]
  onSave?: () => void
  onSearch?: () => void
  onReset?: () => void
}

function FilterList({ className, isLoading, onSave, onSearch, onReset, list, buttons }: Props) {
  const isRequiredNotFilled = useMemo(() => {
    return list.some((item) =>
      item.some(({ required, value }) => {
        return required && !((value as ReactElement).props.value || (value as ReactElement).props.values)
      }),
    )
  }, [list])

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <FilterListWrap className={className}>
        <FieldList list={list} />
        <ButtonWrap gap={8} p="12px 20px" justify="flex-end">
          {buttons?.length && buttons?.map((btn, idx) => <Button key={`filter-list-${btn.content}-${idx}`} {...btn} />)}
          {onSave && (
            <Button kind="primary_dark" type="button" disabled={isLoading} onClick={onSave}>
              저장
            </Button>
          )}
          {onReset && (
            <Button kind="secondary" type="button" disabled={isLoading} onClick={onReset}>
              초기화
            </Button>
          )}
          {onSearch && (
            <Button kind="primary_light" type="submit" disabled={isRequiredNotFilled || isLoading} onClick={onSearch}>
              검색
            </Button>
          )}
        </ButtonWrap>
      </FilterListWrap>
    </form>
  )
}

const FilterListWrap = styled(VStack)`
  background: ${colorSystem.bg.filter};
  padding-bottom: 16px;
  ${borderRadius.md}
`

const ButtonWrap = styled(HStack)``

export default FilterList
