import React, { HTMLInputTypeAttribute, RefObject, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { borderRadius, colorSystem, fontSystem } from '@/styles/theme'
import { HStack } from '@/components/content/Stack'

export interface InputProps {
  id?: string
  className?: string
  label?: string
  block?: boolean
  disabled?: boolean
  type?: HTMLInputTypeAttribute
  value?: string | number
  ref?: RefObject<HTMLInputElement>
  placeholder?: string
  onChange?: (v: string) => void
  onEnter?: (v: string) => void
  onClick?: () => void
  regex?: RegExp
  readOnly?: boolean
}

function Input({
  id,
  className,
  label,
  type = 'text',
  block = false,
  disabled,
  regex,
  readOnly = false,
  ref,
  value,
  placeholder,
  onEnter,
  onChange,
  onClick,
}: InputProps) {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e) e.preventDefault()
      if (onChange) {
        const value = e.target.value || ''
        if (!value) {
          onChange(value)
          return
        }

        if (regex) {
          onChange(value.replace(regex, ''))
        } else if (type === 'number') {
          onChange(value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))
        } else {
          onChange(value)
        }
      }
    },
    [type, onChange],
  )

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && onEnter) onEnter(value?.toString() ?? '')
    },
    [onEnter],
  )

  return (
    <InputWrap block className={className} align="center" gap={8} $block={block}>
      {label && <label>{label}</label>}
      <StyledInput
        ref={ref}
        id={id}
        type={type === 'number' ? 'tel' : type || 'text'}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder}
        onChange={handleChange}
        onClick={onClick}
        onKeyDown={handleKeyDown}
      />
    </InputWrap>
  )
}

const InputWrap = styled(HStack)<{ $block?: InputProps['block'] }>`
  ${({ $block }) =>
    !$block &&
    css`
      width: auto;
    `}

  > label {
    width: 88px;
    text-align: left;
    ${fontSystem.Body_14_medium};

    & + input {
      width: calc(100% - 88px - 8px) !important;
    }
  }
`

const StyledInput = styled.input`
  width: 100%;
  height: 28px;
  padding: 3px 8px 0;
  border: 1px solid ${colorSystem.border.element};
  color: ${colorSystem.text.common.primary};
  ${borderRadius.sm}
  ${fontSystem.Body_14_regular};

  &::placeholder {
    color: ${colorSystem.text.common.tertiary};
  }

  &:focus {
    border: 1px solid ${colorSystem.border.element_active};
    color: ${colorSystem.text.common.primary};
  }

  &:read-only {
    cursor: default;
    background-color: ${colorSystem.bg.input.disabled};
  }

  &:disabled {
    background-color: ${colorSystem.bg.input.disabled};
  }
`

export default Input
