import { REDUX_PERSIST_KEY } from '@/pages/legacy/consts/consts'
import { useEffect } from 'react'

export const useStoreSessionReset = () => {
  // 새로고침 시, 임베딩 페이지의 redux 초기화
  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.removeItem(REDUX_PERSIST_KEY)
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])
}
