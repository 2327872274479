import { css, DefaultTheme } from 'styled-components'

export const MODAL_OPENED_CN = 'modal-open'

export type ColorTypes = typeof colors
export type ColorSystemTypes = typeof colorSystem

const hexToRgba = (hex: string) => {
  const hexValue = hex.replace('#', '')
  const r = parseInt(hexValue.slice(0, 2), 16)
  const g = parseInt(hexValue.slice(2, 4), 16)
  const b = parseInt(hexValue.slice(4, 6), 16)
  const a = parseInt(hexValue.slice(6, 8), 16) / 255 || 1
  return `rgba(${r}, ${g}, ${b}, ${a})`
}

export const colors = {
  red900: '#320F0F',
  red800: '#641E1E',
  red700: '#952C2C',
  red600: '#C73B3B',
  red500: '#F94A4A',
  red400: '#FB8989',
  red300: '#FCADAD',
  red200: '#FDC9C9',
  red100: '#FEE4E4',
  blue900: '#0B1E2F',
  blue800: '#163D5E',
  blue700: '#225B8E',
  blue600: '#2D7ABD',
  blue500: '#3898EC',
  blue400: '#60ADF0',
  blue300: '#88C1F4',
  blue200: '#AFD6F7',
  blue100: '#D7EAFB',
  blue50: '#E8F4FF',
  yellow900: '#332800',
  yellow800: '#665000',
  yellow700: '#997700',
  yellow600: '#CC9F00',
  yellow500: '#FFC700',
  yellow400: '#FFD84C',
  yellow300: '#FFE380',
  yellow200: '#FFEBA6',
  yellow100: '#FFF4CC',
  gray900: '#050505',
  gray800: '#242424',
  gray700: '#393939',
  gray600: '#7E7E7E',
  gray500: '#929292',
  gray400: '#B3B3B3',
  gray300: '#D9D9D9',
  gray200: '#E6E6E6',
  gray100: '#F1F1F1',
  gray050: '#F7F7F7',
  black: '#000000',
  white: '#FFFFFF',
}

/* usages
 * in css
 * color: ${({ theme }) => theme.colorSystem.bg.common.highlight};
 */
export const colorSystem = {
  bg: {
    common: {
      highlight: colors.blue500,
      primary: colors.gray800,
      primary_disabled: colors.gray200,
      primary_disabled_inverse: colors.gray200,
      primary_hover: colors.gray700,
      primary_hover_inverse: colors.red500,
      primary_inverse: colors.red400,
      primary_pressed: colors.gray700,
      primary_pressed_inverse: colors.red600,
      secondary: colors.white,
      secondary_hover: colors.gray100,
      secondary_pressed: colors.gray200,
      secondary_disabled: colors.gray200,
      dim: hexToRgba(`${colors.gray800}b3`),
    },
    input: {
      disabled: colors.gray200,
      enabled: colors.white,
      disabled_inverse: colors.gray700,
      enabled_inverse: colors.gray700,
    },
    table: {
      column_selected: colors.gray200,
      column: colors.gray100,
    },
    lnb: {
      normal: colors.gray800,
      hover: colors.gray700,
      active: colors.gray900,
    },
    filter: colors.gray100,
  },
  border: {
    lnb: colors.gray800,
    element: colors.gray300,
    element_active: colors.gray900,
    table: colors.gray200,
    pinnedTable: colors.gray400,
    divider: colors.gray300,
  },
  text: {
    common: {
      highlight: colors.red500,
      primary: colors.gray800,
      tertiary: colors.gray400,
      link: colors.blue500,
      disabled: colors.gray400,
      disabled_inverse: colors.gray600,
      success: colors.blue500,
      error: colors.red500,
      secondary: colors.gray600,
      inverse: colors.white,
    },
    table: {
      column: colors.gray500,
      count: colors.blue500,
      cell: colors.gray800,
    },
  },
  icon: {
    error: colors.red500,
    input: {
      disabled_inverse: colors.gray600,
      disabled: colors.gray300,
      enabled: colors.gray500,
    },
    lnb: {
      sub_active: colors.gray600,
      sub_enabled: colors.gray600,
      disabled: colors.gray700,
      active: colors.blue500,
      enabled: colors.gray600,
      sub_disabled: colors.gray800,
    },
    secondary: colors.gray500,
    tertiary: colors.gray100,
    primary: colors.gray800,
    primary_light: colors.white,
    primary_dark: colors.gray800,
  },
}

export const fontSystem = {
  Display_32_bold: css`
    font-size: 32px;
    line-height: 36px;
    font-weight: 700;
  `,
  Headline_24_bold: css`
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
  `,
  Headline_20_bold: css`
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  `,
  Headline_16_bold: css`
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
  `,
  Headline_16_regular: css`
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  `,
  Body_14_bold: css`
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
  `,
  Body_14_medium: css`
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  `,
  Body_14_regular: css`
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  `,
  Body_12_bold: css`
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
  `,
  Body_12_medium: css`
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
  `,
  Body_12_regular: css`
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  `,
}

export const buttonSystem = {
  primary_light: css`
    border: 1px solid ${colorSystem.bg.common.primary};
    background: ${colorSystem.bg.common.primary};
    color: ${colorSystem.text.common.inverse};

    &:hover {
      background: ${colorSystem.bg.common.primary_hover};
    }

    &:active {
      background: ${colorSystem.bg.common.primary_pressed};
    }

    &:disabled {
      color: ${colorSystem.text.common.disabled};
      background: ${colorSystem.bg.common.primary_disabled};
      border-color: ${colorSystem.bg.common.primary_disabled};
    }
  `,
  primary_dark: css`
    background: ${colorSystem.bg.common.primary_inverse};
    color: ${colorSystem.text.common.primary};

    &:hover {
      background: ${colorSystem.bg.common.primary_hover_inverse};
    }

    &:active {
      background: ${colorSystem.bg.common.primary_pressed_inverse};
    }

    &:disabled {
      color: ${colorSystem.text.common.disabled};
      background: ${colorSystem.bg.common.primary_disabled_inverse};
    }
  `,
  secondary: css`
    border: 1px solid ${colorSystem.border.element};
    background: ${colorSystem.bg.common.secondary};
    color: ${colorSystem.text.common.primary};

    &:hover {
      background: ${colorSystem.bg.common.secondary_hover};
    }

    &:active {
      background: ${colorSystem.bg.common.secondary_pressed};
    }

    &:disabled {
      color: ${colorSystem.text.common.disabled};
      background: ${colorSystem.bg.common.secondary_disabled};
    }
  `,
  tertiary: css`
    background: transparent;
    color: ${colorSystem.text.common.secondary};

    &:hover {
      background: ${colorSystem.bg.common.secondary_hover};
      color: ${colorSystem.text.common.secondary};
    }

    &:active {
      background: ${colorSystem.bg.common.secondary_pressed};
      color: ${colorSystem.text.common.secondary};
    }

    &:disabled {
      color: ${colorSystem.text.common.disabled};
    }
  `,
  gray: css`
    background: ${colors.gray100};
    color: ${colorSystem.text.common.primary};

    &:hover {
      background: ${colors.gray050};
      color: ${colorSystem.text.common.primary};
    }

    &:active {
      background: ${colors.gray200};
      color: ${colorSystem.text.common.primary};
    }

    &:disabled {
      background: ${colors.gray050};
      color: ${colorSystem.text.common.disabled};
      cursor: not-allowed;
    }
  `,
  link: css`
    background: transparent;
    color: ${colors.blue500};
    text-decoration: underline;

    &:hover,
    &:active {
      font-weight: 700;
    }

    &:disabled {
      color: ${colorSystem.text.common.disabled};
    }
  `,
}

export const buttonSize = {
  sm: css`
    height: 28px;
    padding: 4px 16px;
    ${fontSystem.Body_14_medium};
  `,
  md: css`
    height: 36px;
    padding: 8px 16px;
    ${fontSystem.Body_14_medium};
  `,
  lg: css`
    height: 56px;
    padding: 16px 24px;
    ${fontSystem.Headline_16_bold};
  `,
}

export const zIndexSystem = {
  modal: 1050,
}

export const borderRadiusSystem = {
  sm: 4,
  md: 8,
  lg: 12,
}

export const borderRadius = {
  sm: css`
    border-radius: ${borderRadiusSystem.sm}px;
  `,
  md: css`
    border-radius: ${borderRadiusSystem.md}px;
  `,
  lg: css`
    border-radius: ${borderRadiusSystem.lg}px;
  `,
}

const theme: DefaultTheme = {
  colors,
  colorSystem,
}

export default theme
