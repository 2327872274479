import { MenuOnSession } from '@/services/auth/menu.types'
import { isArray } from 'lodash-es'

export class MenuOnSessionWrap {
  name = ''

  constructor(name: string) {
    this.name = name
  }

  get = () => {
    let list: MenuOnSession[] = []

    try {
      const curMenuOnSession = sessionStorage.getItem(this.name) || ''
      if (curMenuOnSession) list = JSON.parse(curMenuOnSession) || []
    } catch (e) {
      // ERROR
      // console.dir(e)
    }

    return isArray(list) ? list : []
  }

  add = ({ code, name, link }: MenuOnSession) => {
    const list = this.get()
    if (!list.find((menu) => menu.link === link)) {
      list.push({ code, name, link })
      sessionStorage.setItem(this.name, JSON.stringify(list))
    }
  }

  remove = (code: string) => {
    const list = this.get()
    const idxToRemove = list.findIndex((menu) => menu.code === code)
    const menuToRemove = list.splice(idxToRemove, 1)?.[0]

    sessionStorage.setItem(this.name, JSON.stringify(list))

    return [menuToRemove, idxToRemove] as const
  }

  clear = () => {
    sessionStorage.removeItem(this.name)
  }
}
