import { REDUX_PERSIST_KEY } from '@/pages/legacy/consts/consts'

export const useStoreSessionDelete = () => {
  const onMenuDelete = (_menuCode: string) => {
    const menuCode = _menuCode.toUpperCase()
    try {
      const value = sessionStorage.getItem(REDUX_PERSIST_KEY) ?? ''
      if (value) {
        const redux = JSON.parse(value)
        const grid = JSON.parse(redux.grid)
        const filterInfo = JSON.parse(redux.sub)
        delete grid[`${menuCode}_HEAD_DATA`]
        delete grid[`${menuCode}_HEAD_FILTER_COUNT`]
        delete grid[`${menuCode}_HEAD_TOTAL_COUNT`]
        delete filterInfo[`${menuCode}_PARAMS`]

        redux.grid = JSON.stringify(grid)
        redux.sub = JSON.stringify(filterInfo)

        sessionStorage.setItem(REDUX_PERSIST_KEY, JSON.stringify(redux))
      }
    } catch (e) {
      sessionStorage.removeItem(REDUX_PERSIST_KEY)
    }
  }
  return {
    onMenuDelete,
  }
}
