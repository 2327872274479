import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { MENU_ON_SESSION_HEIGHT } from '@/utils/consts'
import { MenuOnSession } from '@/services/auth/menu.types'
import { useLocation, useNavigate } from 'react-router-dom'
import { colors } from '@/styles/theme'
import { MenuStorage } from '@/utils/storage/MenuStorage'
import RemixIcon from '@/components/content/RemixIcon'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { FreeMode, Mousewheel } from 'swiper/modules'
import { useStoreSessionDelete } from '@/pages/legacy/hooks/useStoreSessionDelete'

interface Props {
  list: MenuOnSession[]
  onChangeMenuOnSession: (v: MenuOnSession[]) => void
}

function MainMenuOnSession({ list, onChangeMenuOnSession }: Props) {
  const { onMenuDelete } = useStoreSessionDelete()
  const [swiper, setSwiper] = useState<SwiperClass>()

  const prevPathname = useRef<string>('')
  const location = useLocation()
  const navigate = useNavigate()
  const onCloseMenuOnSession = useCallback(
    (code: string) => {
      const [removedMenu, removedIdx] = MenuStorage.menuOnSession.remove(code)
      onMenuDelete(code)

      setTimeout(() => {
        const updatedMenuList = MenuStorage.menuOnSession.get()
        onChangeMenuOnSession(updatedMenuList)
        // 닫는 메뉴가 현재 링크인 경우에 페이지 이동 처리.
        if (removedMenu.link === window.location.pathname) {
          navigate((updatedMenuList[removedIdx] || updatedMenuList[updatedMenuList.length - 1])?.link || '/')
        }
      }, 0)
    },
    [onChangeMenuOnSession],
  )

  useEffect(() => {
    if (prevPathname.current === location.pathname) {
      return
    }

    const index = list.findIndex((item) => item.link === location.pathname)
    if (index !== -1) {
      prevPathname.current = location.pathname
      swiper?.slideTo(index)
    }
  }, [location.pathname, list, swiper])

  return (
    <MainMenuOnSessionWrapper>
      <Swiper
        freeMode
        slidesPerView="auto"
        autoplay={{ disableOnInteraction: false }}
        modules={[FreeMode, Mousewheel]}
        mousewheel={{
          enabled: true,
          forceToAxis: true,
          invert: false,
          sensitivity: 1,
          releaseOnEdges: false,
        }}
        onSwiper={setSwiper}
      >
        {list.map((menu) => (
          <SwiperSlide key={`moms-menu-on-session-${menu.link}`}>
            <MainMenuOnSessionItem $active={location.pathname === menu.link} key={`moms-menu-on-session-${menu.link}`}>
              <span onClick={() => navigate(menu.link)} role="button">
                {menu.name}
              </span>
              <BtnClose>
                <RemixIcon name="ri-close-line" onClick={() => onCloseMenuOnSession(menu.code)} />
              </BtnClose>
            </MainMenuOnSessionItem>
          </SwiperSlide>
        ))}
      </Swiper>
    </MainMenuOnSessionWrapper>
  )
}

const MainMenuOnSessionWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: #202020;
  height: ${MENU_ON_SESSION_HEIGHT}px;
  overflow-x: scroll;
  scrollbar-width: none;

  .swiper {
    width: 100%;
    height: 100%;

    .swiper-slide {
      width: 184px;
    }
  }
`

const MainMenuOnSessionItem = styled.div<{ $active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  padding: 0 24px 0 12px;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    height: 100%;
    color: ${colors.gray400};
  }

  &:hover > span {
    color: ${colors.white};
  }

  ${({ $active }) =>
    $active &&
    css`
      > span {
        color: ${colors.white};
      }

      &:after {
        content: '';
        width: 100%;
        height: 3px;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        background-color: ${colors.red500};
      }
    `}
`

const BtnClose = styled.span`
  position: absolute;
  right: 2px;
  font-size: 20px;
`

export default MainMenuOnSession
