import * as React from 'react'
import styled from 'styled-components'
import { ChangeEvent, useCallback } from 'react'
import { HStack } from '@/components/content/Stack'
import { fontSystem } from '@/styles/theme'

interface Props<T> {
  value: T
  checked: boolean
  disabled?: boolean
  onChange: (value: T) => void
  label?: string
  className?: string
}

function Radio<T extends string>({ value, checked, disabled, onChange, label, className }: Props<T>) {
  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value as T), [onChange])

  return (
    <RadioWrap className={className} gap={8} align="center" p="4px">
      <RadioInput type="radio" id={value} value={value} checked={checked} disabled={disabled} onChange={handleChange} />
      {label && <RadioLabel htmlFor={value}>{label}</RadioLabel>}
    </RadioWrap>
  )
}

const RadioWrap = styled(HStack)``

const RadioInput = styled.input`
  height: 16px;
`

const RadioLabel = styled.label`
  ${fontSystem.Body_14_regular}
  line-height: 1;
`

export default Radio
