import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import { MenuItem, MenuItemModel, MenuOnSession, MenuParent } from '@/services/auth/menu.types'
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '@/utils/consts'
import { useGetMenuList } from '@/services/auth/useAuthQueries'
import { colors } from '@/styles/theme'
import { isEmpty } from 'lodash-es'
import { MenuStorage } from '@/utils/storage/MenuStorage'
import { AuthStorage } from '@/utils/storage/AuthStorage'
import RemixIcon from '@/components/content/RemixIcon'

export const SIDEBAR_OPENED_WIDTH = 240
export const SIDEBAR_CLOSED_WIDTH = 64
export const MENU_ICON_MAP: StringMap = {
  ma: 'ri-table-line',
  gr: 'ri-donut-chart-line',
  rgr: 'ri-delete-back-2-line',
  gi: 'ri-truck-line',
  st: 'ri-box-2-line',
  rp: 'ri-bar-chart-2-line',
  ts: 'ri-reply-all-line',
  sy: 'ri-settings-3-line',
  iv: 'ri-book-line',
  bl: 'ri-calculator-line',
  hs: 'ri-history-line',
  gf: 'ri-translate-2',
  tm: 'ri-store-line',
  gl: 'ri-global-line',
}

function MainSideBar({ onChangeMenuOnSession }: { onChangeMenuOnSession: (v: MenuOnSession[]) => void }) {
  const navigate = useNavigate()
  const location = useLocation()
  const [open, setOpen] = useState(true)

  const { data: menuList, refetch: menuRefetch } = useGetMenuList()
  useEffect(() => {
    if (AuthStorage.auth.get()) {
      menuRefetch()
    }
  }, [])

  const [parentPath, childPath] = useMemo(() => location.pathname.slice(1).split('/'), [location.pathname])
  const [activeParent, setActiveParent] = useState(parentPath)

  const onClickChild = useCallback((parentCd: string, child: MenuItemModel) => {
    setActiveParent(parentCd)

    MenuStorage.menuOnSession.add({ code: child.menuCode, link: child.link, name: child.menuName })
    onChangeMenuOnSession(MenuStorage.menuOnSession.get())
    navigate(child.link)
  }, [])

  return (
    <SideBarWrapper $open={open}>
      <SideBarMenuParent onClick={() => setOpen(!open)}>
        <RemixIcon name={open ? 'ri-close-line' : 'ri-menu-line'} />
      </SideBarMenuParent>
      {menuList?.map(({ menuCode, menuName, menuItem }) => {
        const isActive = activeParent === menuCode

        return (
          <React.Fragment key={`moms-side-bar-parent-${menuCode}`}>
            <SideBarMenuParent
              $active={isActive}
              onClick={() => {
                if (!open) setOpen(true)
                setActiveParent(open && isActive ? '' : menuCode)
              }}
            >
              {MENU_ICON_MAP[menuCode] ? <RemixIcon name={MENU_ICON_MAP[menuCode]} size={22} /> : null}
              {open ? menuName : ''}
            </SideBarMenuParent>
            {isActive && open && (
              <SideBarMenuChildren>
                {menuItem.map((child) => (
                  <SideBarMenuChild
                    $active={childPath === child.menuCode}
                    key={`moms-side-bar-child-${child.menuCode}`}
                    onClick={() => onClickChild(menuCode, child)}
                  >
                    {child.menuName}
                  </SideBarMenuChild>
                ))}
              </SideBarMenuChildren>
            )}
          </React.Fragment>
        )
      })}
    </SideBarWrapper>
  )
}

const SideBarWrapper = styled.div<{ $open: boolean }>`
  width: ${({ $open }) => ($open ? SIDEBAR_OPENED_WIDTH : SIDEBAR_CLOSED_WIDTH)}px;
  min-width: ${({ $open }) => ($open ? SIDEBAR_OPENED_WIDTH : SIDEBAR_CLOSED_WIDTH)}px;
  height: calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px);
  overflow-y: auto;
  background-color: ${colors.black};
  color: ${colors.gray300};
  transition: width 0.2s;
`

const SideBarMenuParent = styled.div.attrs({ role: 'button' })<{ $active?: boolean }>`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 20px;
  height: 50px;
  font-size: 14px;
  position: relative;
  z-index: 1;

  &:hover {
    color: ${colors.white};
    background-color: ${colors.gray700};
  }

  ${({ $active }) =>
    $active &&
    css`
      color: ${colors.white};
      background-color: ${colors.gray700};
    `}
`

const SideBarMenuChildren = styled.div`
  margin: 8px 0;
  position: relative;
  z-index: 1;

  &:last-of-type {
    margin-bottom: 24px;
  }
`

const SideBarMenuChild = styled.div.attrs({ role: 'button' })<{ $active: boolean }>`
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 16px;
  padding: 0 20px 0 52px;
  height: 28px;

  &:hover {
    color: ${colors.white};
  }

  ${({ $active }) =>
    $active &&
    css`
      color: ${colors.red500};
      font-weight: 700;
      position: relative;

      &:hover {
        color: ${colors.red500};
      }

      &:before {
        content: '';
        position: absolute;
        top: 4px;
        left: 38px;
        height: 20px;
        width: 2px;
        background-color: ${colors.red500};
      }
    `}
`

export default MainSideBar
