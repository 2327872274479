import * as React from 'react'
import styled from 'styled-components'
import Text from '@/components/content/Text'
import { VStack } from '@/components/content/Stack'

interface Props {
  className?: string
  label?: string
  children?: React.ReactNode
}

function RadioGroup({ className, label, children }: Props) {
  return (
    <RadioGroupWrap gap={12} className={className}>
      <Text kind="Body_14_medium">{label}</Text>
      {children}
    </RadioGroupWrap>
  )
}

const RadioGroupWrap = styled(VStack)``

export default RadioGroup
