import styled, { css } from 'styled-components'
import { borderRadius, borderRadiusSystem, colors, colorSystem, fontSystem } from '@/styles/theme'
import { HStack } from '@/components/content/Stack'
import Input from '@/components/content/Input'

const PAGING_PANEL_HEIGHT = 40
export const TABLE_HEADER_EDITABLE = 'moms-header--editable'
export const TABLE_CELL_DISABLED = 'moms-cell--disabled'

export const AgGridReactWrap = styled.div`
  flex: 1;
  height: 100%;
  border: 1px solid ${colorSystem.border.table};
  background-color: ${colors.white};
  border-top-left-radius: ${borderRadiusSystem.md}px;
  border-top-right-radius: ${borderRadiusSystem.md}px;

  .ag-icon:before,
  .ag-checked:after {
    font-family: 'agGridBalham';
  }

  .ag-root-wrapper {
    border: none;
  }

  .ag-header {
    background: ${colorSystem.bg.table.column};
    border-bottom: 1px solid ${colorSystem.border.table};
  }

  .ag-root-wrapper {
    ${borderRadius.md}
  }

  .ag-paging-panel {
    border-top: 1px solid ${colorSystem.border.table};
    height: ${PAGING_PANEL_HEIGHT}px;
  }

  .${TABLE_HEADER_EDITABLE} .ag-header-cell-text {
    color: ${colorSystem.text.common.primary};
  }

  // ROW
  // 마지막 column이 끝까지 채워지도록 설정
  .ag-center-cols-container {
    min-width: 100% !important;
  }

  // CELL
  .ag-cell {
    font-weight: 400;
  }

  .${TABLE_CELL_DISABLED} {
    background-color: ${colors.gray100};
  }

  // CHECKBOX
  --ag-checkbox-background-color: ${colors.white};
  --ag-checkbox-checked-color: ${colors.gray700};
  --ag-checkbox-unchecked-color: ${colorSystem.border.element};
  --ag-checkbox-indeterminate-color: ${colors.white};
  --ag-value-change-value-highlight-background-color: ${colors.blue500};

  .ag-checkbox-input-wrapper {
    box-shadow: none;
    border: 1px solid ${colorSystem.border.element};

    &.ag-indeterminate::after {
      display: none;
    }

    &::after,
    &.ag-checked::after {
      top: -1px;
      left: -1px;
    }

    &.ag-disabled {
      --ag-checkbox-checked-color: ${colorSystem.icon.input.disabled_inverse};
      background-color: ${colorSystem.bg.input.disabled};
      border-color: ${colors.gray400};
    }
  }
`

export const GridToolBarWrap = styled(HStack)`
  margin-top: -1px;
  border: 1px solid ${colorSystem.border.table};
  border-bottom-left-radius: ${borderRadiusSystem.md}px;
  border-bottom-right-radius: ${borderRadiusSystem.md}px;

  [class^='ri-'],
  [class*=' ri-'] {
    cursor: pointer;
    color: ${colorSystem.icon.secondary};

    &:hover {
      color: ${colorSystem.icon.primary};
    }
  }
`

export const GridToolBarSearch = styled(Input)`
  width: 200px !important;
`

export const GridToolPagination = styled(HStack)<{ $loading?: boolean }>`
  flex-grow: 1;
  cursor: default;
  ${fontSystem.Body_12_regular};

  ${({ $loading }) =>
    $loading &&
    css`
      * {
        pointer-events: none !important;
        opacity: 0.75;
      }
    `}
`
