import * as React from 'react'
import Modal from '@/components/content/Modal'
import { useCallback, useMemo, useState } from 'react'
import Radio from '@/components/content/Radio'
import RadioGroup from '@/components/content/RadioGroup'
import { useCompleteExport, useGetExportCombo } from '@/services/excel/useExcelQuries'
import { HStack, VStack } from '@/components/content/Stack'
import Input from '@/components/content/Input'
import styled from 'styled-components'

export interface GridExportModalProps {
  onClose: () => void
  onExportData?: () => void
}

function GridExportModal({ onClose, onExportData }: GridExportModalProps) {
  const [selectCode, setSelectCode] = useState('')
  const [remark, setRemark] = useState('')

  const confirmDisabled = useMemo(() => !selectCode || remark.length < 6, [selectCode, remark])

  const { data: combo } = useGetExportCombo()
  const completeExport = useCompleteExport()

  const onConfirm = useCallback(() => {
    onExportData?.()
    const remarkParams = `${selectCode}_${remark}`
    completeExport.mutateAsync(remarkParams).then(onClose)
  }, [selectCode, remark])

  return (
    <Modal
      open
      closable
      size="md"
      title="다운로드 사유"
      onClose={onClose}
      buttons={[
        {
          content: '취소',
          kind: 'secondary',
          onClick: onClose,
        },
        {
          content: '확인',
          kind: 'primary_light',
          onClick: onConfirm,
          disabled: confirmDisabled,
        },
      ]}
    >
      <ModalContents>
        {combo && (
          <RadioGroup className="code-radio-group" label="사유코드">
            {combo?.DOWNLOAD_CD?.COMBO_OPT_CD?.map((code) => (
              <Radio key={`grid-export-modal-${code}`} value={code} label={code} checked={selectCode === code} onChange={setSelectCode} />
            ))}
          </RadioGroup>
        )}
        <VStack gap={30} style={{ flex: 1 }}>
          <Input value={remark} label="상세사유" onChange={setRemark} placeholder="5글자 이상 입력해주세요." />
        </VStack>
      </ModalContents>
    </Modal>
  )
}

const ModalContents = styled(HStack)`
  .code-radio-group {
    flex: 0.45;
  }
`

export default GridExportModal
